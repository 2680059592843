.CourseSelection {
    .QMeLogo.course {
        position: absolute;
        top: 9px;
        left: 9px;
        display: block;
        margin: auto;
        margin-left: 22px;
        width: 28px;
    }

    .selectionContent {
        margin-top: 46px;
        padding: 20px 0 100px;
        box-sizing: border-box;

        .description {
            .title {
                font-size: 50px;
                line-height: 50px;
                padding-bottom: 40px;
            }

            .subtitle {
                font-size: 20px;

                .EnrolledCourses.mobile {
                    display: none;
                }
            }
        }

        .CourseCards {
            display: flex;
            padding: 10px;
            flex-shrink: 1;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}

.EnrollBar {
    position: fixed;
    padding: 0px 30px;
    left: 0;
    right: 0;
    bottom: 0;
    color: #484848;
    background-color: #fbfbfb;
    height: 100px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // flex-wrap: wrap;

    .EnrolledCourses.web {
        display: block;
    }

    button:hover {
        cursor: pointer;
    }

    .buttons button {
        text-decoration: none;
        display: inline-block;
        border-radius: 4px;
        border: none;
        padding: 0;
        width: 150px;
        height: 40px;
    }

    .buttons form {
        display: inline-block;
    }

    .buttons {
        .switch {
            color: white;
            background-color: #484848;
            margin: 5px;
        }

        .save {
            color: white;
            background-color: #484848;
            margin: 5px;
        }

        .cancel {
            display: inline-block;
            background-color: white;
            border: solid 1px #484848;
            margin: 5px;
        }

        .disabled {
            opacity: 0.7;
            cursor: not-allowed;
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .App {
        background-color: white;
    }

    .CourseSelection {
        background-color: white;

        .QMeLogo.course {
            display: none;
        }

        .topBar {
            display: none;
        }

        .selectionContent {
            margin-top: 0px;

            .CourseCards {
                width: 100%;
            }

            .description {
                padding-bottom: 25px;
                border-bottom: solid 2px #e2e2e2;

                .title {
                    font-size: 24px;
                    padding-bottom: 20px;
                    font-weight: bold;
                }

                .subtitle {
                    font-size: 18px;

                    .EnrolledCourses.mobile {
                        display: none; // Change to block
                    }
                }
            }
        }
    }

    .EnrollBar {
        background-color: white;
        justify-content: space-around;

        .buttons {
            max-width: 500px;
        }

        .EnrolledCourses.web {
            display: none;
        }
    }
}
