.leave-queue-background {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto";
    font-weight: 400px;
    background-color: rgba(112, 112, 112,0.8);
    animation: fade 0.6s;
}

.leave-queue-container {
    position: relative;
    background-color: white;
    border-radius: 8px;
    height: 350px;
    width: 40%;
    animation: moveUp 0.6s;

    .leave-queue-button {
        position: absolute;
        top: 10px;
        right: 10px;
        border: none;
        background-color: transparent;
        padding: 0;
        cursor: pointer;
        width: 15px;
        height: 15px;

        img {
            width: 15px;
            height: 15px;
            margin: 0;
            padding: 0;
        }
    }

    img {
        margin-top: 100px;
        width: 50px;
        height: 50px;
    }

    h2 {
        color: #B41515;
        font-weight: 400;
        font-size: 18px;
    }

    .leave-queue-option {
        color: white;
        width: 50%;
        position: absolute;
        bottom: 0;
        border: none;
        font-size: 18px;
        padding: 18px 0;
        cursor: pointer;
        transition: background-color 0.3s;
    }

    .yes-queue-button {
        left: 0;
        background-color: #B41515;
        border-radius: 0 0 0 8px;
    }

    .yes-queue-button:hover {
        background-color: #C11616;
    }

    .cancel-queue-button {
        right: 0;
        background-color: #5599DB;
        border-radius: 0 0 8px 0;
    }

    .cancel-queue-button:hover {
        background-color: #5EA7EE;
    }
}

@media screen and (max-width: 850px) {
    .leave-queue-container {
        height: 200px;
        width: 80%;
        .leave-queue-button {
            img {
                height: 10px;
                width: 10px;
            }
        }
        img {
            margin-top: 50px;
            height: 30px;
            width: 30px;
        }
        h2 {
            font-size: 12px;
        }
        .leave-queue-option {
            font-size: 12px;
        }
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes moveUp {
    0% {
        transform: translateY(10px)
    }
    100% {
        transform: translateY(0px)
    }
}