.AdminView {
    position: relative;
    .course-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        padding: 2em;
    }

    .create-course-btn {
        display: inline-block;
        background-color: white;
        border: solid 1px #484848;
        margin: 5px;
        width: 150px;
        height: 40px;
        border-radius: 4px;
        text-decoration: none;
        cursor: pointer;
        &:focus {
            outline: none;
          }
    }

    .course {
        width: 100%;
        margin: 1em auto;
        padding: 1em;
        min-height: 100%;

        .course-section {
            margin: 1em;
        }

        .editing-button {
            display: inline-block;
            background-color: white;
            border: solid 1px #484848;
            border-radius: 4px;
            text-decoration: none;
            padding: .5em;
            margin: .25em;
            cursor: pointer;
        }
    
        .roles-button {
            display: inline-block;
            background-color: white;
            border: solid 1px #484848;
            border-radius: 4px;
            text-decoration: none;
            padding: .5em;
            margin: .25em;
            cursor: pointer;
        }
    }
}

@media(max-width: $mobile-breakpoint) {
    .AdminView {
        background-color: #f5f5f5;
    }
}
