// THIS VALUE ALSO LIVES IN SplitView.tsx,
// IF YOU UPDATE THIS, UPDATE THAT
$mobile-breakpoint: 920px;

@import "../../node_modules/react-dates/lib/css/_datepicker.css";

// This is where constants should go.
@import "./system";
// Custom react-datepicker styling
@import "./react-datepicker";

// Calendar
@import "./calendar/CalendarDaySelect";
@import "./calendar/CalendarHeader";
@import "./calendar/CalendarSessions";
@import "./calendar/CalendarView";
@import "./calendar/CalendarWeekSelect";

// Session
@import "./session/AddQuestion";
@import "./session/DetailedQuestionView";
@import "./session/SelectedTags";
@import "./session/SessionInformationHeader";
@import "./session/SessionJoinButton";
@import "./session/SessionPopularQuestion";
@import "./session/SessionPopularQuestionsContainer";
@import "./session/SessionQuestionsComponent";
@import "./session/SessionQuestionsContainer";
@import "./session/TASessionView";
@import "./session/SessionAlertModal";
@import "./session/SessionNotification";
@import "./session/DiscussionQuestion";
@import "./session/UserComment";
@import "./session/CommentsContainer";
@import "./session/NewComment";

// Login
@import "./login/LoginView";
@import "./login/LoginButton";
@import "./Footer.scss";

// Split
@import "./SplitView";
@import "./MessageModal";

// Multiple views
@import "./TopBar";
@import "./FeedbackPopup";
@import "./ModalMenu";
@import "./LeaveQueueModal";
@import "./TimeLimitBanner";

// Professor
@import "./professor/ProfessorView";
@import "./professor/ProfessorSidebar";
@import "./professor/ProfessorAddNew";
@import "./professor/ProfessorCalendarTable";
@import "./professor/ProfessorDashboardView";
@import "./professor/ProfessorCalendarRow";
@import "./professor/ProfessorOHInfo";
@import "./professor/ProfessorTagsTable";
@import "./professor/ProfessorTagsRow";
@import "./professor/ProfessorTagInfo";
@import "./professor/ProfessorDelete";
@import "./professor/ProfessorSettings";
@import "./professor/ProfessorOHInfoDelete";
@import "./professor/ProfessorTagsDelete";
@import "./professor/ProfessorPeopleView";
@import "./professor/ProfessorRoles";
@import "./professor/CSVUploadView";

// Admin
@import "./AdminView";

// Courses
@import "./courses/CourseCard";
@import "./courses/CourseSelection";

// Blog Posts
@import "./blogPosts/AddBlogPost.scss";
@import "./blogPosts/BlogPostDisplay.scss";
@import "./blogPosts/ProductUpdates.scss";
@import "./blogPosts/Notifications.scss";

@import "./Announcements.scss";
#root {
  height: 100%;
}

.App {
  text-align: center;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  background: #f5f5f5 !important;
}

ul {
  color: #747474;
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: left;

  li {
    border-bottom: solid 1px #747474;
    padding: 5px 0;
  }
}

nav {
  background-color: #eee;
}
