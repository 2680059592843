.CSVWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.CSVBox {

    .HeadContainer {
        width: 969px;
        background-color: #5599DB;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 65px;
        color: white;
        font-size: 25px;
        margin: 20px 20px 0px 20px;
        padding: 10px 0px 10px 0px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .StepBody {
        width: 969px;
        background-color: white;
        margin: 0px 20px 0px 20px;
        min-height: 400px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        

        .UploadBoxes {
            height: 400px;
            width: 95%;
        }

        .UploadBox, .CheckedUploadBox {
            margin: 20px;
            height: 40%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #F1EFEF;
            border-radius: 10px;
            font-size: 30px;
        }

        .CheckedUploadBox {
            background: #D6EAFE;
            border: 5px solid #5599DB;
            font-weight: bold;
            img {
                padding-left: 12px;
            }
        }

        .CSVUpload {
            width: 90%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .UploadMessage, .ErrorMessage {
            background-color: #E4EFFF;
            color: #7C8FBA;
            font-size: 15px;
            font-weight: 500;
            display: flex;
            align-items: center;
            height: 60px;
            width: 90%;
            padding: 12px;
            margin: 21px;

            img {
                padding-right: 12px;
            }
        }

        .ErrorMessage {
            background: #FFDFDF;
            color: #FF5A60;
            width: 60%;
            margin-top: 0;
            margin-left: 0;
            margin-right: 0; 
            margin-bottom: 40px;
        }

        .DragDrop {
            width: 60%;
            height: 217px;
            background: #F1EFEF;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-style: italic;
            color: #4A4A4A;
            margin: 35px;
            border: 2px dashed #979797;
            font-weight: 500;
            padding-left: 10px;
            padding-right: 10px;

            img {
                padding: 13px;
            }
        }

        p {
            color: #4A4A4A;
            font-size: 20px;
            margin: 0;
            font-weight: 500;
        }

        .SelectFileBox {
            display: flex;
            align-items: center;
            width: 60%;
            height: 138px;
            margin: 35px;
            border: 2px solid #979797;
            border-radius: 10px;
        }

        .SelectFile {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            margin: 26px;

            p, Button {
                margin: 18px;
                font-weight: 500;
            }    
        }

        .File {
            p {
                margin: 11px;
                font-size: 20px;
                font-weight: 500;
                color: #4A4A4A;
                font-style: italic;
            }

            #FileType {
                font-size: 15px;
                font-weight: 400;
                font-style: normal;
            }
            
        }

        .FileName {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                display: inline-flex;
                padding-left: 7px;
                cursor: pointer; 
            }
        }

        Button.ChooseFile {
            width: 165px;
            height: 37px;
            background: #EEEEEE;
            border: 2px solid #979797;
            border-radius: 4px;
            font-size: 18px;
            font-weight: 500;
            color: #4A4A4A;
        }

        #FileLink, #FileLinkRed {
            font-style: italic;
            font-weight: 500;
            font-size: 18px;
            text-decoration-line: underline;
            color: #5599DB;
        }

        #FileLinkRed {
            color: #FF5A60;
        }
    }

    .FinalStep, .EnterEmails {
        width: 100%;
        text-align: left;
        width: 60%;
        padding-top: 25px;
        padding-bottom: 25px;

        p {
            color: #4A4A4A;
            font-size: 20px;
            font-weight: 500;
            padding-bottom: 10px;
        }

        .ErrorMessage {
            width: 100%;
            margin-top: 20px;
        }
        
    }

    .RolesTable {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    table {
        border-collapse: collapse;
        width: 100%;
        margin-top: 7px;
        margin-bottom: 7px;

        th {
            height: 55px;
            border: 3px solid #979797;
            padding-left: 25px;
            text-align: left;
            font-weight: 500;
            font-size: 16px;
            color: #4A4A4A;
            width: 50%; 
            overflow: hidden;
        }

        #TableHeader {
            background: #E8E8E8;
        }

        [contenteditable=true]:empty:before {
            content: attr(placeholder);
            pointer-events: none;
            display: block;
            color: #979797;
            font-style: italic;
        }
    }

    .EnterEmails {
        padding-top: 50px;
        text-align: center;
        .UploadMessage {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
        }

        img {
            padding-top: 30px;
            padding-bottom: 25px;
        }

        table {
            th {
                padding-left: 0;
            }

            #TableHeader th {
                padding-left: 25px;
            }

            input {
                width: 100%;
                height: 100%;
                background-color: transparent;
                outline: none;
                border: none;
                padding-left: 25px;
            }
        }
    }
}

.StepControls {
    padding-top: 35px;
    display: flex;
    justify-content: space-between;
    margin: 20px 30px 10px 30px;

    .dots {            

        .ondot {
            height: 15px;
            width: 15px;
            background-color: #5599DB;
            border-radius: 50%;
            display: inline-block;
            margin-left: 10px;
            margin-right: 10px;
        }

        .offdot {
            height: 15px;
            width: 15px;
            background-color: #C4C4C4;
            border-radius: 50%;
            display: inline-block;
            margin-left: 10px;
            margin-right: 10px;
        }

    }

    Button {
        border: 1px solid #898989;
        border-radius: 4px;
        min-width: 120px;
        height: 32.92px;
        font-size: 14px;
    }

    Button.leftbutton {
        color: #898989;
        background-color: white;  
    }

    Button.rightbutton { 
        color: white;
        background-color: #979797;
        cursor: default;

    }
    
    Button.selectableButton {
        background-color: #5599DB;
        color: white;
    }

}