.topBar .triggerArea,
.topBar:focus,
.userProfile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    outline: none;
}

.MenuBox:focus {
    outline: none;
}

.desktop.logoutMenu {
    border-radius: 5px;
    position: absolute;
    width: 300px;
    right: 8px;
    top: 50px;
    left: auto;
    overflow: hidden;
    bottom: auto;
    box-shadow: 1px 0 5px 0 rgba(0, 0, 0, 0.25);
    padding: 0;

    li {
        padding-top: 16px;
    }

    li:last-child {
        border: none;
    }

    span {
        width: 100%;
        padding-right: 10px;
    }
}

.topBar {
    background-color: white;
    width: 100%;
    height: 48px;
    padding-right: 46px;
    box-shadow: 0 1px 6px 1px rgba(208, 208, 208, 0.47);
    user-select: none;

    .triggerArea {
        width: 100%;
        padding: 8px;
        display: flex;

        .viewToggles {
            display: flex;
            flex-wrap: wrap;
            align-self: center;
            justify-content: center;
        }

        .QMILogo {
            align-self: right;
            margin-bottom: 10px;
            width: 30px;
            margin-left: 46px;
        }
        .rightContentWrapper {
            width: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .userProfile {
            align-self: right;
            padding-bottom: 10px;
            transition-duration: 0.2s;
            transition-property: box-shadow, color;
            transition-timing-function: $curve-standard;
            cursor: pointer;
        }

        .userProfile:hover {
            box-shadow: inset 0 -2px #b7bfc6;
        }

        .userProfile:hover .name {
            color: $text-grey;
        }

        i.icons .corner.icon {
            top: 0;
            bottom: auto;
        }

        .profilePic {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            margin: 0px 12px 0px 0px;
        }

        .name {
            font-size: 15px;
            font-weight: 500;
            color: #898989;
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .userProfile {
        padding-right: 15px;
    }

    .topBar {
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 0;

        .triggerArea {
            .QMILogo {
                margin-left: 15px;
            }
        }
    }
}
